import React from 'react';
import './global.js';


class QuizManager extends React.Component {

    /**
     * TODO:
     *  map these calls in postman 
     */


    constructor(props) {
        super(props);

        // Bind the this context to the handler function
        // this.addAnswer = this.addAnswer.bind(this);
        var today = new Date(),
        date = "Created On: " + today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + " " + today.getHours() + ":" + today.getMinutes();


        var minDate = new Date(),
        startDate = minDate.getFullYear() + '-' + (minDate.getMonth() + 1) + '-' + minDate.getDate();

        this.state = {
            questions: [],
            currentId: 0,//initiate with the highest question id + 1 in the passed array
            quizName: date,
            minDate: startDate,
            sendDate: '',
            blurb: 'Place Blurb Here.',
            quizId: 0, //this.props.quizId,
            quizes: [],
        }
    }

    componentDidMount = () => {
        const quizId = this.state.quizId;//props
        fetch(global.server + "quizes/read.php?id=" + quizId).then(res => res.json()).then((result) => {
            let x = 0;
            result.questions.forEach(question => {
                if (question.questionId > x) {
                    x = question.questionId;
                }
                question.answers.forEach(answer => {
                    if (answer.id > x) {
                        x = answer.id;
                    }
                })
            })


            this.setState({
                questions: result.questions,
                currentId: x,
                blurb: result.blurb,
                quizName: result.quizName,
                quizId: result.quizId
            });
        });

        fetch(global.server + "quiznames/read.php").then(res => res.json()).then((result) => {
            this.setState({
                quizes: result,
            })
        });
    }


    removeQuestion = e =>{
        const question = e.target.id.substring(14);
        const questionId = parseInt(question);
        const array = [...this.state.questions];
        let deleteIndex = -1;
        for (let index = 0; index < this.state.questions.length; index++) {
            if(this.state.questions[index].questionId === questionId)
            {
                deleteIndex = index;
                break;
            }
        }
        array.splice(deleteIndex,1);
        this.setState({
            questions: array,
        });
    }

    removeAnswer = e =>{
        const answer = parseInt(e.target.id.substring(12));
        const question = parseInt(e.target.name);
        let outQuestionIndex = -1;
        let outAnswerIndex = -1;
        for (let index = 0; index < this.state.questions.length; index++) {
            if(this.state.questions[index].questionId === question)
            {
                for (let index2 = 0; index2 < this.state.questions[index].answers.length; index2++) {
                    if(this.state.questions[index].answers[index2].id === answer)
                    {
                        outQuestionIndex = index;
                        outAnswerIndex = index2;
                        break;
                    }
                }
            }
        }
        const questions = [...this.state.questions];
        const array = [...questions[outQuestionIndex].answers];
        array.splice(outAnswerIndex,1);
        questions[outQuestionIndex].answers = array;
        const result = questions;
        this.setState({
            questions: result,
        });
    }

    addShortQuestion = () => {
        const newQuestion = {questionId: this.state.currentId + 1, question: '',type:0 , answers: [{id:this.state.currentId + 2, answer: '' }, {id:this.state.currentId + 3, answer: '' }]}
        this.setState({
            questions: [...this.state.questions, newQuestion],
            currentId: this.state.currentId + 3,
        });
        
        // const stateData = JSON.stringify(this.state);
    }

    addLongQuestion = () => {
        const passId = this.state.currentId + 1;
        const newQuestion = { question: '', answers: [], type: 1, questionId: passId }
        this.setState({
            questions: [...this.state.questions, newQuestion],
            currentId: this.state.currentId + 1,
        });
    }
   
    addQuiz = e =>{
        let hold = {
            questions: this.state.questions,
            currentId: this.state.currentId,
            quizName: this.state.quizName,
            minDate: this.state.minDate,
            sendDate: this.state.sendDate,
            blurb: this.state.blurb,
            quizId: this.state.quizId
        };

        const stateData = JSON.stringify(hold);
        const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: stateData,};

        fetch(global.server + "quizes/create.php", requestOptions).then(alert('success')).then(() =>
            this.resetFields()
        );
    }
    

    addAnswer = e =>{
        const question = parseInt(e.target.name);
        let outIndex = -1;
        for (let index = 0; index < this.state.questions.length; index++) {
            if(this.state.questions[index].questionId === question)
            {
                outIndex = index;
                break;
            }
        }
        const newAnswer = {id:this.state.currentId + 1, answer: '',correct:0};
        const questions = [...this.state.questions];
        const array = [...this.state.questions[outIndex].answers];
        array.push(newAnswer);
        questions[outIndex].answers = array;
        const result = questions;
        this.setState({
            questions: result,
            currentId: this.state.currentId + 1
        });
    }

    repopulateQuiz = (quizId) => {
        fetch(global.server + "quizes/read.php?id=" + quizId).then(res => res.json()).then((result) => {
            let x = 0;
            result.questions.forEach(question => {
                if (question.questionId > x) {
                    x = question.questionId;
                }
                question.answers.forEach(answer => {
                    if (answer.id > x) {
                        x = answer.id;
                    }
                })
            })

            this.setState({
                questions: result.questions,
                currentId: x,
                blurb: result.blurb,
                quizName: result.quizName,
            });
        });
    }


    updateAnswer = e =>
    {
        let answer = parseInt(e.target.id.substring(6));
        let answerString = e.target.value;
        let question = parseInt(e.target.name);
        if(answerString !== '')
        {
            let outQuestionIndex = -1;
            let outAnswerIndex = -1;
            for (let index = 0; index < this.state.questions.length; index++) {
                if(this.state.questions[index].questionId === question)
                {
                    for (let index2 = 0; index2 < this.state.questions[index].answers.length; index2++) {
                        if(this.state.questions[index].answers[index2].id === answer)
                        {
                            outQuestionIndex = index;
                            outAnswerIndex = index2;
                            break;
                        }
                    }
                }
            }
            const questions = this.state.questions;
            questions[outQuestionIndex].answers[outAnswerIndex].answer = answerString;
            // const result = questions;
            this.setState({
                questions: questions,
            });
        }
    }

    updateQuestion = e =>
    {
        let questionId = parseInt(e.target.name);
        let question = e.target.value;
        if(question !== ''){
            const array = [...this.state.questions];
            let deleteIndex = -1;
            for (let index = 0; index < this.state.questions.length; index++) {
                if(this.state.questions[index].questionId === questionId)
                {
                    deleteIndex = index;
                    break;
                }
            }
            array[deleteIndex].question = question
            this.setState({
                questions: array,
            });
        }
    }

    updateQuizName = e =>
    {
        let newQuizName = e.target.value;
        this.setState({
            quizName: newQuizName,
        });
    }


    updateSendDate = e =>
    {
        let dateToSend = e.target.value;
        alert(dateToSend);
        this.setState({
            sendDate: dateToSend,
        });
    }

    updateBlurb = () => {
        const blurbValue = document.getElementById("blurbInput").value;
        this.setState({
            blurb: blurbValue,
        });
    }

    updateQuiz = e =>{
        const newQuizId = parseInt(e.target.value);
        if(newQuizId === 0)
        {    
            this.setState({
                quizId: newQuizId,
                questions: [],
            },
            this.repopulateQuiz(newQuizId))
        }else{
            this.setState({
                quizId: newQuizId,
            },
            this.repopulateQuiz(newQuizId))
        }
    }


    resetFields = () => {
        var today = new Date(),
        date = "Created On: " + today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + " " + today.getHours() + ":" + today.getMinutes();
        fetch(global.server + "quiznames/read.php").then(res => res.json()).then((result) => {
                this.setState({
                    quizes: result,
                    questions:[],
                    quizName: date,
                    blurb: 'Place Blurb Here.',
                })
            })
            document.getElementById('blurbInput').value="";
            document.getElementById('quizName').value="";
    }

    deleteQuiz = () =>
    {
        const quizId = document.getElementById("QuizSelect").value;
        const requestOptions = {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                id:quizId
            }),
        };
        
        fetch(global.server + "quizes/delete.php",requestOptions).then(res => res.json()).then(result => {
            this.resetFields();
        });
    }
    
    updateAnswerCorrect = (e) => {
        let answer = parseInt(e.target.id.substring(7));
        let checkHuh = e.target.checked;
        let question = parseInt(e.target.name);
        
        let outQuestionIndex = -1;
        let outAnswerIndex = -1;
        for (let index = 0; index < this.state.questions.length; index++) {
            if(this.state.questions[index].questionId === question)
            {
                for (let index2 = 0; index2 < this.state.questions[index].answers.length; index2++) {
                    if(this.state.questions[index].answers[index2].id === answer)
                    {
                        outQuestionIndex = index;
                        outAnswerIndex = index2;
                        break;
                    }
                }
            }
        }
        const questions = [...this.state.questions];
        questions[outQuestionIndex].answers[outAnswerIndex].correct = checkHuh;
        const result = questions;
        this.setState({
            questions: result,
        });
        
    }


    render() {
        var today = new Date(),
        date = "Created On: " + today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + " " + today.getHours() + ":" + today.getMinutes();
        

        const multiplesInDiv = (
            <div>
                <button onClick={this.deleteQuiz}>Delete Quiz</button>
                <QuizSelect quizId={this.state.quizId} updateQuiz={this.updateQuiz} quizes={this.state.quizes}></QuizSelect>
                <textarea id="blurbInput" placeholder={this.state.blurb} onBlur={this.updateBlurb}></textarea>
                <table><thead></thead><tbody><tr><td>
                    <button key={Math.random(0, 5000)} onClick={this.addShortQuestion}>Add Multiple Choice Question</button>
                </td><td>
                    <button key={Math.random(0, 5000)} onClick={this.addLongQuestion}>Add Short Answer Question</button>
                </td></tr></tbody><tfoot></tfoot></table>
                {this.state.questions.map(question => (
                    <div key={Math.random(500000)}>
                        <table key={Math.random(500000)}><thead key={Math.random(500000)}></thead><tbody key={Math.random(500000)}><tr key={Math.random(500000)}><td key={Math.random(500000)}>
                            <input onBlur={this.updateQuestion} id={"question" + question.questionId} name={question.questionId} placeholder={question.question} key={Math.random(500000)} />
                        </td><td key={Math.random(500000)}>
                            <button onClick={this.removeQuestion} id={"questionButton" + question.questionId} key={Math.random(500000)} >Delete</button>
                        </td><td key={Math.random(500000)}>
                            <button onClick={this.addAnswer} name={question.questionId} key={Math.random(500000)}>Add Answer</button>
                        </td></tr></tbody><tfoot key={Math.random(500000)}></tfoot></table>
                        <QuestionBlock updateAnswerCorrect={this.updateAnswerCorrect} updateAnswer={this.updateAnswer} question={question} removeAnswer={this.removeAnswer} >{this.props.children}</QuestionBlock>
                    </div>
                )
                )}
                <table><thead></thead><tbody><tr><td>
                    <label htmlFor={'quizName'} style={{float:'right'}}>Quiz Name </label>
                </td><td>
                    <input id={'quizName'} placeholder={(this.state.quizId === 0)?date:this.state.quizName} onBlur={this.updateQuizName} />
                </td><td>
                    <input type='date' onChange={this.updateSendDate} min={this.state.minDate}></input>
                </td><td>
                    <button onClick={this.addQuiz}>Submit</button>
                </td></tr></tbody><tfoot key={Math.random(500000)}></tfoot></table>
            </div>
        );
        return multiplesInDiv;
    }
}


/**
 * <div className='addquizcontrolls'>
                    <lavel for={'quizName'}>Quiz Name</lavel>
                    <input id={'quizName'} placeholder={this.state.quizName} onBlur={this.updateQuizName} />
                    <input type='date'></input>
                    <button onClick={this.addQuiz}>Submit</button>
                </div>
 */

class QuestionBlock extends React.Component {

    render() {
        const multiplesInDiv = (
            <table key={Math.random(500000)}><thead key={Math.random(500000)}></thead><tbody>
                    {this.props.question.answers.map(answer =>(
                        <tr key={Math.random(500000)}><td key={Math.random(500000)}>
                        <label htmlFor={"correct" + answer.id}>Correct Answer</label>
                    </td><td key={Math.random(500000)}>
                            <input id={"correct" + answer.id} type="checkbox" onChange={this.props.updateAnswerCorrect} name={this.props.question.questionId} checked={(answer.correct===0? (false):(true))}/>
                        </td><td key={Math.random(500000)}>
                            <input onBlur={this.props.updateAnswer} id={"answer" + answer.id} name={this.props.question.questionId} placeholder={answer.answer} key={Math.random(500000)}/>
                            </td><td key={Math.random(500000)}>
                            <button onClick={this.props.removeAnswer} id={"answerButton" + answer.id} name={this.props.question.questionId} key={Math.random(500000)}>Delete</button> 
                        </td></tr>
                    ))}
            </tbody><tfoot key={Math.random(500000)}></tfoot></table>
        );
        return multiplesInDiv;
    }
}

class QuizSelect extends React.Component {

    render() {
        const multiplesInDiv = (
            <select id="QuizSelect" onChange={this.props.updateQuiz} value={this.props.quizId}>
                <option value={0} key={Math.random(50000)}>Make A New Quiz</option>
                {
                    this.props.quizes.map(quiz =>(
                        <option value={quiz.id} key={Math.random(50000)}>{quiz.quiz_name}</option>
                    ))
                }
            </select>
            );
            return multiplesInDiv;
        }
    }






export default QuizManager;