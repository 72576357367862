import React from 'react';
import './global.js';


class PointManager extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            points: [],
            classId: this.props.classId,
            school: this.props.school,
        }
    }

    componentDidMount = () => {
        fetch(global.server + 'points/read.php?ClassId=' + this.state.classId + '&school=' + this.state.school).then(res => res.json()).then((result) => {
            this.setState({
                points: result,
            })
        });
    }

    updatePoints = () => {
        fetch(global.server + 'points/read.php?ClassId=' + this.state.classId + '&school=' + this.state.school).then(res => res.json()).then((result) => {
            if(result.result){
                this.setState({
                    points: result,
                })    
            }
        });
    }

    addPoint = () => {
        let pointValue = document.getElementById('pointValue').value;
        let description = document.getElementById('description').value;
        let activity = document.getElementById('activity').value;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                points: pointValue,
                description: description,
                activity: activity
            }),
            };
        
        fetch(global.server + 'points/create.php',requestOptions).then(res => res.json()).then(result => {
            if (result.result) {
                this.updatePoints();
                alert("Successfully added " + activity);
            }
        });
    }

    deletePoint = (e) => {
        let pointId = e.target.id;
        let activity = e.target.name;
        const requestOptions = {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                id:pointId
            }),
        };
        
        fetch(global.server + 'points/delete.php',requestOptions).then(res => res.json()).then(result => {
            if (result.result) {
                this.updatePoints();
                alert("Successfully deleted " + activity);
            }                    
        });
    }

    render() {


        const multiplesInDiv = (
            <div>
                <div className='smallcard' key={this + 'exField1'}>
                    <h1>Add Points Here</h1>
                    <div className='newPoint'>
                        <table>
                            <thead></thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <input id='activity' placeholder='Point Name' key={Math.random(0, 5000)} />
                                    </td><td>
                                        <input id='description' placeholder='Point Description' key={Math.random(0, 5000)} />
                                    </td><td>
                                        <input id='pointValue' type='number' placeholder='Point Value' key={Math.random(0, 5000)} />
                                    </td><td>
                                        <button onClick={this.addPoint}>Add Point</button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot></tfoot>
                        </table>
                    </div>
                </div>
                <div className='smallcard'>
                    <h1>Manage Points Here</h1>

                    <table>
                        <thead></thead>
                        <tbody>
                            {
                                this.state.points.map(point => {
                                    return(
                                    <tr key={Math.random(0, 5000)}>
                                        <td>
                                            <input id={point.id + 1} name={point.id} placeholder={point.activity} key={this + 'input'} />
                                        </td><td>
                                            <input id={point.id + 2} name={point.id} placeholder={point.points} key={this + 'textarea'} />
                                        </td><td>
                                            <input id={point.id + 3} name={point.id} placeholder={point.description} key={this + 'textarea'} />
                                        </td><td>
                                            <button id={point.id} name={point.activity} onClick={this.deletePoint}>Delete</button>
                                        </td>
                                    </tr>)
                                })
                            }

                        </tbody>
                        <tfoot></tfoot>
                    </table>
                </div>
            </div>
        );

        return multiplesInDiv;
    }
}

/**TODO:
 * make sure this is for points and not points....
 */


export default PointManager;