import React from 'react';
import './global.js';

//import PrizeSelect from './prizeselect';

class PrizeAdder extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            prizes:[],
        }
    }
    
    componentDidMount = () =>{
        this.getAvailablePrizes();

    }

    getAvailablePrizes = () => {
        fetch(global.server + "prizes/read.php").then(res => res.json()).then(result => {
            this.setState({
                prizes:result,
                file:'',
            })
        });
    }

    deletePrize = e => {
        let delId = e.target.id;
        let id = delId.substr(delId.indexOf(":") + 1);
        const requestOptions = {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                id:id
            }),
            };
        
        fetch(global.server + "prizes/delete.php",requestOptions).then(res => res.json()).then(result => {
            if(result.result)
            {
                alert("Successfully deleted it");
            }
        }).then(() => {
            this.getAvailablePrizes();
        });
    }

    addPrize = event => {
        let prizeName = document.getElementById("prizename").value;
        let file = document.getElementById("fileToUpload").files[0];
		event.preventDefault();

		let data = new FormData();
		data.append('file', file);//this.state.file);
        data.append('prizename',prizeName);

		fetch(global.server + 'prizes/create.php', {
			method: 'POST',
			body: data
		}).then(() => {alert("Done");}).then(() => {
            this.getAvailablePrizes();
        });
    }

    onFileChange = event => {
		this.setState({
			file: event.target.files[0]
		});

        event.target.ref = "test";
    }

    render() {
        const multiplesInDiv = (
            <div key={Math.random(0,5000)}>
                <div key={Math.random(0,5000)} style={{width:"75%",margin:"0 auto"}}>				
                    <input type='file' name='fileToUpload' id='fileToUpload' />
                    <input type='text' id='prizename' name='prizename' placeholder='Prize Name'/>
                    <button onClick={this.addPrize}>Add Prize</button>
                </div>
                <div key={Math.random(0,5000)}>
                    <table>
                        <tbody>
                        {
                            this.state.prizes.map(prize => {
                                return (
                                    <tr key={Math.random(50000)}>
                                        <td><button id={"prizeAdderId:" + prize.id} onClick={this.deletePrize}>Delete</button></td>
                                        <td>{prize.prize_name}</td>
                                        <td><img alt={prize.prize_name} src={global.server + "images/prizes/" + prize.file_location} style={{maxWidth:"150px",float:"left"}}/></td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        );

        return multiplesInDiv;
    }
}

export default PrizeAdder;