import React from 'react';
import './global.js';
//import ReactLoading from 'react-loading';


class Signup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            gradesOptions: [
                { id: 1, name: 'Pre-K' },
                { id: 2, name: 'Kindergarten' },
                { id: 3, name: 'First' },
                { id: 4, name: 'Second' },
                { id: 5, name: 'Third' },
                { id: 6, name: 'Fourth' },
                { id: 7, name: 'Fifth' },
                { id: 8, name: 'Sixth' },
                { id: 9, name: 'Seventh' },
                { id: 10, name: 'Eighth' },
                { id: 11, name: 'Freshman' },
                { id: 12, name: 'Sophomore' },
                { id: 13, name: 'Junior' },
                { id: 14, name: 'Senior' }
            ], schoolOptions: [],
            isLoading:false,
            childArr:[],
            childIndex:1,
        }
    }

    componentDidMount = () => {
            fetch(global.server + "schools/read.php").then(res => res.json()).then(result => {
                this.setState({
                    schoolOptions: result
                })
            });
    }

    signUp = () => {
        var email = document.getElementById('email').value;
        var emailVer = document.getElementById('emailVer').value;
        var pass = document.getElementById('pass').value;
        var passVer = document.getElementById('passVer').value;
        var name = document.getElementById('name').value;
        // let children = [];
        // this.state.childArr.forEach(child => {
        //     let chname = document.getElementById('childName' + child).value;
        //     var egrade = document.getElementById('childGrade' + child);
        //     var chgrade = egrade.options[egrade.selectedIndex].id;
        //     var eschool = document.getElementById('childSchool' + child);
        //     var chschool = eschool.options[eschool.selectedIndex].id;
        //     children.push({name:chname,grade:chgrade,school:chschool})
        // })
        const childname = this.state.childArr;//children;
        let childZero = document.getElementById('childName0').value;
        //var childname = document.getElementById('childName').value;
        var eschool = document.getElementById("School");
        var school = eschool.options[eschool.selectedIndex].id;
        var egrade = document.getElementById("Grade");
        var grade = egrade.options[egrade.selectedIndex].id;
        var schoolcode = document.getElementById('schoolCode').value;
        let e = document.getElementById("account-type");
        let accounttype = e.options[e.selectedIndex].value;
        this.props.setLoading(true);
        if( email === '' || 
            emailVer === '' ||
            pass === '' || 
            passVer === '' ||
            name === '' ||
            school === '' ||
            grade === '' ||
            schoolcode === '' ||
            accounttype === '')
            {
                this.props.setLoading(false);
                alert("You Left Something Out...")
            }else{
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        authority: accounttype,
                        email: email,
                        emailVer: emailVer,
                        pass: pass,
                        passVer: passVer,
                        name: name,
                        school: school,
                        grade: grade,
                        schoolcode: schoolcode,
                        childZero: childZero,
                        childName: childname
                    }),
                };

                fetch(global.server + "users/create.php", requestOptions).then(res =>res.json()).then((result) =>{
                    alert(result.response);
                    this.props.setLoading(false);
                });
                        // if(res === undefined){
                        //     throw new Error('Something went wrong');
                        // }else if(res.ok){
                            // alert(JSON.stringify(res));
                            // return res.json();         
                        // } else {
                        //     throw new Error('Something went wrong');
                        // }
                //});
            }
        }

    updateFields = () => {
        let e = document.getElementById("account-type");
        let val = e.options[e.selectedIndex].id;

        if (val === "student") {
            document.getElementById("childName0").style.display = "none";
            document.getElementById("childNameDiv").style.display = "none";
        } else if (val === "parent") {
            document.getElementById("childName0").style.display = "block";
            document.getElementById("childNameDiv").style.display = "block";
        } else if (val === "teacher") {
            document.getElementById("childName0").style.display = "none";
            document.getElementById("childNameDiv").style.display = "none";
        }
    }

    addchildspot = () => {
        let newChildArr = [...this.state.childArr]
        let newChildIndex = this.state.childIndex + 1;
        
        newChildArr.push({id:newChildIndex});
        this.setState({
            childArr:newChildArr,
            childIndex:newChildIndex,
        })
    }

    removeChildSpot = e => {
        let childId = e.target.id;
        let newChildArr = [];
        this.state.childArr.forEach(child => {
            if(child !== childId)
            {
                newChildArr.push(child);
            }
        })
        this.setState({
            childArr:newChildArr,
        })
    }

    updateChildren = e => {
        let value = e.target.value;
        let id = e.target.id;

        if(id.indexOf('childName') !== -1)
        {
            id=id.substring(9,);
            this.updateChildInArray(id,'childName',value,value);
        }else if(id.indexOf('childSchool') !== -1)
        {
            var eschool = document.getElementById(id);
            var school = eschool.options[eschool.selectedIndex].id;
            id=id.substring(11,);
            this.updateChildInArray(id,'childSchool',school,value);
        }else if(id.indexOf('childGrade') !== -1)
        {
            var egrade = document.getElementById(id);
            var grade = egrade.options[egrade.selectedIndex].id;
            id=id.substring(10);
            this.updateChildInArray(id,'childGrade',grade,value);
        }
    }

    updateChildInArray = (id,typeVal,valu,valStr) => {
        let newCArr = [];
        this.state.childArr.forEach(child => {
            if(child.id === id)
            {
                let newChild = {
                    name:child.name,
                    school:child.school,
                    schoolVal:child.schoolVal,
                    grade:child.grade,
                    gradeVal:child.gradeVal,
                    id:id
                };
                if(typeVal === 'childName')
                {
                    newChild.name = valu;
                    newChild.nameVal = valStr
                }
                if(typeVal === 'childSchool')
                {
                    newChild.school = valu;
                    newChild.schoolVal = valStr
                }
                if(typeVal === 'childGrade')
                {
                    newChild.grade = valu;
                    newChild.gradeVal = valStr
                }
                newCArr.push(newChild);
            }else{
                newCArr.push(child);
            }
        })
        this.setState({
            childArr:newCArr,
        })
    }

    compareEmails = () => {
        var email = document.getElementById('email').value;
        var emailVer = document.getElementById('emailVer').value;
        if(email !== emailVer)
        {
            document.getElementById('emailVerLabel').style.display = "block";
        }else{
            document.getElementById('emailVerLabel').style.display = "none";
        }
    }

    comparePasswords = () => {
        var pass = document.getElementById('pass').value;
        var passVer = document.getElementById('passVer').value;
        if(pass !== passVer)
        {
            document.getElementById('passVerLabel').style.display = "block";
        }else{
            document.getElementById('passVerLabel').style.display = "none";
        }
    }

    render() {
        const multiplesInDiv = (
            <div className='signinForm'>
                <h1>Sign Up</h1>
                <select id="account-type" onChange={this.updateFields} defaultValue="0">
                    <option value={0} id="student">Student</option>
                    <option value={0} id="parent">Parent/Guardian</option>
                    <option value={52} id="teacher">Teacher</option>
                </select>
                <label id='emailVerLabel' htmlFor='email' style={{color:"red",display:'none'}}>Emails Do Not Match</label>
                <input id='email' placeholder='Email' onKeyUp={this.compareEmails}/>
                <input id='emailVer' placeholder='Email Verification' onKeyUp={this.compareEmails}/>
                <label id='passVerLabel' htmlFor='pass' style={{color:"red",display:'none'}}>Passwords Do Not Match</label>
                <input type='password' id='pass' placeholder='Password' onKeyUp={this.comparePasswords} />
                <input type='password' id='passVer' placeholder='Password Verification' onKeyUp={this.comparePasswords} />
                <input id='name' placeholder='Your Name' />
                <input id={'childName0'} style={{ display: "none" }} placeholder="Your Child's Name" />
                <SelectComp id='School' options={this.state.schoolOptions} idNameSelect={'School'}></SelectComp>
                <SelectComp id='Grade' idNameSelect={'Grade'} options={this.state.gradesOptions}></SelectComp>
                <div id='childNameDiv' style={{ display: "none" }}>
                    {
                        this.state.childArr.map(childspot => {
                            return(
                                <div key={Math.random(50000)}>
                                    <input key={Math.random(50000)} id={'childName' + childspot.id} placeholder="Your Child's Name" onBlur={this.updateChildren} defaultValue={childspot.name}/>
                                    <SelectComp key={Math.random(50000)} idNameSelect={'School'} id={'childSchool' + childspot.id} options={this.state.schoolOptions} passedOnChange={this.updateChildren} defaultValue={childspot.schoolVal}></SelectComp>
                                    <SelectComp key={Math.random(50000)} idNameSelect={'Grade'} id={'childGrade' + childspot.id} options={this.state.gradesOptions} passedOnChange={this.updateChildren} defaultValue={childspot.gradeVal}></SelectComp>
                                    <button key={Math.random(50000)} id={childspot.id} onClick={this.removeChildSpot}>Delete</button>
                                </div>
                            )
                        })
                    }
                    <button onClick={this.addchildspot}>Add Another Child</button>
                </div>
                <input id='schoolCode' placeholder='School Code' />
                <button onClick={this.signUp}>Sign Up</button>
            </div>
        );

        return multiplesInDiv;
    }
}

class SelectComp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return (
            <select id={this.props.id} onChange={this.props.passedOnChange} defaultValue={this.props.defaultValue}>
                <option defaultValue>Please Select A {this.props.idNameSelect}</option>
                {this.props.options.map(opt => (
                    <option id={opt.id} key={opt.id + opt.name} >{typeof opt.name !== 'undefined'?opt.name:opt.school}</option>
                ))}
            </select>
        )
    }
}

export default Signup;