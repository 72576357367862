import React from 'react';
import Points from './points';
import Prize from './prize';
import Messenger from './messenger';
import PointTracker from './pointtracker';
import QRMaker from './QR-maker';


class Dashboard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        }
        this.props.updatePoints();
    }

    render(){
        //let opt = this.props.user;
        const points = this.props.user.points;
        const prize = this.props.user.prize;
        const userId = this.props.user.userId;
        //const theirId = opt["theirId"];
        const auth = this.props.user.auth;
        const school = this.props.user.school;
        
        const multiplesInDiv = (
            <div className='dashboard'>
                <div className='banner'>
                    <Points points={points}></Points>
                </div>
                <div className='smallcard' style={{overflow:"hidden"}}>
                    <Prize saveState={this.props.saveState} prize={prize} userId={userId} updatePrize={this.props.updatePrize}></Prize>
                </div>
                <div className='smallcard' style={{overflow:"hidden"}}>
                <Messenger auth={auth} userId={userId} school={school}></Messenger>
                </div>
                <div className='smallcard' style={{overflowY: "scroll"}}>
                    <PointTracker userId={userId}></PointTracker>
                </div>
                <div className='smallcard' style={{overflow:"hidden"}}>
                    <QRMaker userId={userId + ""}></QRMaker>
                </div>
                {/**<div className='fab'>
                    <div className='crossbar'></div>
                    <div className='post'></div>
                </div>**/}
            </div>
        );

        return multiplesInDiv;
    }
}

/***TODO:
 * Integrate ICAL and GoogleCalendar
 * Titles on cards. for users
 * 
 */

export default Dashboard;