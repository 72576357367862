import React from 'react';
import './global.js';


import PointTracker from './pointtracker';


class WinnersPage extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            userId: 0,
            winners:[{"name":"TeacherTest2","userName":"TeacherTest2","UserEmail":"test2@git.com","PrizeName":"nulled.jpg","href":"Unselected","Points":0,"id":1034,"is_clamed":0,"auth":52}],
            page:0,
            count:5,
            totalPages:1,
            pages:[[{"name":"TeacherTest2","userName":"TeacherTest2","UserEmail":"test2@git.com","PrizeName":"nulled.jpg","href":"Unselected","Points":0,"id":1034,"is_clamed":0,"auth":52}]]//[{page:1,winners:[{users stuff}]},{page:2,winners:[{users stuff}]}]
        }

        // this.state.userId = this.props.userId;
    }
    
    componentDidMount = () => {
        let newPages = this.state.pages;
        fetch(global.server + "point_tracker/read.php?Count=" + this.state.count + "&Page=" + this.state.page).then(res => res.json()).then((result) => {
            newPages.push(result);
            console.log("test");
            console.log(result);
            this.setState({
                pages: newPages,
                winners: result,
                userId: this.props.userId
            })
        }).then(() => {
            fetch(global.server + "prizes/read.php?Pages=" + this.state.count).then(res => res.json()).then((result) =>{
                this.setState({
                    totalPages:result.count
                })
            })
        });
    }

    updateWinner = (winnersIn) => {
        this.setState({
            winners: winnersIn,
        });
    }

    getPageWinners = (page) => {
        if(this.contentFound(page)){
            this.getNew(page,this.state.count);
        }
        this.updateWinner(this.state.pages[page]);
    }

    getNew = (page) => {
        let newPages = this.state.pages;
        fetch(global.server + "prizes/read.php?Count=" + this.state.count + "&Page=" + page).then(res => res.json()).then((result) => {
            newPages.push(result);
            this.setState({
                pages:newPages,
                winners: result
            })
        });
    }

    nextPage = () => {
        let newPage = 0;
        // if(this.state.page + 1 > 0)
        // {
        //     newPage = 0;
        // }else{
            newPage = this.state.page + 1;
        // }
        this.setState({
            page: newPage
        },() => {this.getNew(newPage)})
    }
    prevPage = () => {
        let newPage = 0;
        if(this.state.page - 1 < 0)
        {
            newPage = 0;
        }else{
            newPage = this.state.page - 1;
        }
        this.setState({
            page: newPage
        },() => {this.getNew(newPage)})
    }

    newPage = (e) => {
        let newPage = parseInt(e.target.value) - 1;
        this.setState({
            page: newPage
        },() => {this.getNew(newPage)})
    }

    refreshPage = () => {
        let newPage = this.state.page;
        this.setState({
            page: newPage
        },() => {this.getNew(newPage)})
    }


    contentFound = (page) => {
        if(this.state.pages.find(x => x.page === page))
        {
            return true;
        }else{
            return false;
        }
    }

    
    render(){
        const multiplesInDiv = (
            <table key={Math.random(0,5000)}>
                <thead key={Math.random(0,5000)}></thead>
                <tbody key={Math.random(0,5000)}>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>User Name</th>
                        <th>User Email</th>
                        <th>Prize</th>
                        <th>Points</th>
                        <th>Point Tracker</th>
                    </tr>
                    {this.state.winners.map(prize => (
                        <UserPlacement curentPage={this.state.page} refresh={this.refreshPage} prizeIn={prize} key={Math.random(0,5000)} userId={prize.id}></UserPlacement>
                    ))}
                    <tr>
                        <th>{}</th>
                        <th>{"0..." + (this.state.page - 1) + " " + this.state.page}</th>
                        <th><button onClick={this.prevPage}>{"<"}</button></th>
                        <th><input onBlur={this.newPage} placeholder={this.state.page + 1}></input></th>
                        <th><button onClick={this.nextPage}>{">"}</button></th>
                        <th>{(this.state.page + 2) + " " + (this.state.page + 3) + "..." + this.state.totalPages}</th>
                        <th>{}</th>
                    </tr>
                </tbody>
                <tfoot key={Math.random(0,5000)}></tfoot>
            </table>
        );

        return multiplesInDiv;
    }
}

class UserPlacement extends React.Component{
    
    constructor(props)
    {    
        super(props);
        this.state = {
          prizeIn: this.props.prizeIn,
        };
    }

    

    givePrize = (e) => {
        const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    is_clamed:1,
                    user_id:e.target.id
                }),
              };
          
            fetch(global.server + "prize_select/update.php",requestOptions).then(res => res.json()).then(result => {
                // this.props.refresh();
            });
    }

    render(){
        const prizeIn = this.props.prizeIn;
        let earnedPrize = (prizeIn.Points - (prizeIn.is_clamed * 100)) > 100;
        console.log(prizeIn);
        const out = (this.props.prizeIn.auth ? (<tr></tr>):(
            <tr className='userRow' key={Math.random(0,5000)}>
                <td key={Math.random(0,5000)} style={(!earnedPrize ? ({backgroundColor:"#FEECE7"}):({backgroundColor:"red"}))}>
                    <button value={this.props.curentPage} onClick={this.givePrize} key={Math.random(0,5000)} disabled={!earnedPrize} id={prizeIn.id}>{prizeIn.id}</button>
                </td><td key={Math.random(0,5000)}>
                    {prizeIn.userName}
                </td><td key={Math.random(0,5000)}>
                    {prizeIn.UserEmail}
                </td><td key={Math.random(0,5000)}>
                    <img style={{width:"50px"}} alt='Prize' src={"./images/prizes/" + prizeIn.href} />
                </td><td key={Math.random(0,5000)}>
                    {prizeIn.PrizeName}
                </td><td key={Math.random(0,5000)}>
                    Points {prizeIn.Points}
                </td><td key={Math.random(0,5000)} style={{height:"150px"}}>
                    <PointTracker limit={5} userId={prizeIn.id} height={"150px"}>{this.props.children}</PointTracker>
                </td>
            </tr>
        ));
        return out;
    }
}


/**
 * TODO:
 * headers.
 * Acheavment date
 * 
 */

export default WinnersPage;