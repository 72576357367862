import React from 'react';

class StudentField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    addBox = (student) =>{
        if(this.props.selectedStudents.includes(student.user_id))
        {
            return(
                <input type='checkbox' id={student.user_id} name='studentsSelected' key={Math.random(0,50000)} onChange={this.props.updateStudents} defaultChecked/>
            );
        }else{
            return(
                <input type='checkbox' id={student.user_id} name='studentsSelected' key={Math.random(0,50000)} onChange={this.props.updateStudents} />
            );
        }
    }

//className='studentfield'
//className='studentRow' 
    render() {
        const multiplesInDiv = (
            <table id="studentField">
                <thead>
                </thead>
                <tbody>
                    <tr>
                        <th><button onClick={this.props.checkAll}>Check All</button></th>
                        <th>Student</th>
                        <th><button onClick={this.props.uncheckAll}>Uncheck All</button></th>
                    </tr>
                    {this.props.students.map(student => (
                        <tr key={Math.random(0,50000)}>
                            <td>
                                {this.addBox(student)}
                            </td>
                            <td>
                                <label htmlFor={student.user_id} >{student.name}</label>
                            </td>
                            <td>
                                {student.points}
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot></tfoot>
            </table>
        );

        return multiplesInDiv;
    }
}

export default StudentField;