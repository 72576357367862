import React from 'react';

class GradePicker extends React.Component {
    constructor(props){
        super(props);
        this.state={
            grades:[
                { id: 1, name: 'Pre-K' },
                { id: 2, name: 'Kindergarten' },
                { id: 3, name: 'First' },
                { id: 4, name: 'Second' },
                { id: 5, name: 'Third' },
                { id: 6, name: 'Fourth' },
                { id: 7, name: 'Fifth' },
                { id: 8, name: 'Sixth' },
                { id: 9, name: 'Seventh' },
                { id: 10, name: 'Eighth' },
                { id: 11, name: 'Freshman' },
                { id: 12, name: 'Sophomore' },
                { id: 13, name: 'Junior' },
                { id: 14, name: 'Senior' }],
        }
    }

    render() {
        const multiplesInDiv = (
            <div>
                <select id='gradeIdSelect' onChange={this.props.setGrade} value={this.props.grade}>
                    <option id={0} value={0} disabled>Please Select A Grade</option>
                    {this.state.grades.map(grade => (
                        <option id={grade.id} value={grade.id} key={Math.random(0, 50000)}>{grade.name}</option>
                    ))}
                </select>
            </div>
        );
        return multiplesInDiv;
    }
}

export default GradePicker;