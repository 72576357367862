import React from 'react';
import ReactLoading from 'react-loading';

class Conversations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectMessages:false,
            redirectContacts:false,
            timeoutId: '',
            isLoading:false,
        }
    }

    componentDidMount = () => {
        this.props.setContacts();
        let timeoutId = setTimeout(this.updateContacts, 10000);
        this.setState({
            timeoutId: timeoutId,
            isLoading:false,
        });
    }

    componentWillUnmount = () => {
        window.clearTimeout(this.state.timeoutId);
    }

    updateContacts = () => {
        this.setState({isLoading:true});
        this.props.setContacts();
        let timeoutId = setTimeout(this.updateContacts,10000);
        this.setState({
            timeoutId: timeoutId,
            isLoading:false,
        });
    }

    gotoConversation = e => {
        let idIn = e.currentTarget.id;
        this.props.setContact(idIn);
    }

    addConversation = () => {
        this.setState({
            redirectContacts:true,
        })
    }

    render() {

        const multiplesInDiv = (
            this.state.isLoading ? (
              <div className="loading-screen">
                <img src="logo192.png" alt="involvED" className="signin-logo" />  
                <ReactLoading className="loading-bars" type={"bars"} color="grey" />
              </div>
            ):(
            <div className="conversationsField" key={Math.random(5000)}>
                {this.props.conversations.map(conversation => {
                    return <ConversationTab 
                                key={Math.random(5000)}
                                gotoConversation={this.gotoConversation} 
                                conversation={conversation}
                                userId={this.props.userId}/>
                })}
            </div>
        ));

        return multiplesInDiv;
    }
}

class ConversationTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        let newMessage = this.props.conversation.newMessage;
        const multiplesInDiv = (
                <div
                    key={Math.random(5000)}
                    id={this.props.conversation.toUserId} 
                    onClick={this.props.gotoConversation} 
                    className={newMessage?"conversation":"newConversation"}
                >
                    <p key={Math.random(5000)}>{this.props.conversation.toUser}</p>
                </div>
            
        );
        return multiplesInDiv;
    }
}

export default Conversations;