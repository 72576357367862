import React from 'react';


class Quiz extends React.Component {
    render() {
        const updateValues = (questionIn) => {
            let type = questionIn.type;
            if (type === 0) {
                return <ShortQuestion answers={questionIn.answers} question={questionIn.question} questionId={questionIn.questionId}></ShortQuestion>;
            } else {
                return <LongQuestion question={questionIn.question}></LongQuestion>;
            }
        }

        const multiplesInDiv = (
            <div key={Math.random(0, 5000)}>
                <p>{this.props.blurb}</p>
                {
                    this.props.questions.map(question => (
                        <div key={Math.random(0, 5000)}>
                            <p key={Math.random(0, 5000)}
                                style={{marginLeft: "15px"}}>
                                {question.question}
                            </p>
                            <table key={Math.random(0, 5000)}
                                    style={{display: "table"}}>
                                <thead key={Math.random(0, 5000)}></thead>
                                <tbody key={Math.random(0, 5000)}>
                                    {updateValues(question)}
                                </tbody>
                                <tfoot key={Math.random(0, 5000)}>
                                </tfoot>
                            </table>
                        </div>
                    ))
                }
            </div>
        );

        return multiplesInDiv;
    }
}

class ShortQuestion extends React.Component {

    handleOptionChange(){
        //alert(this.id);
    }

    render() {
        var answers = this.props.answers;//JSON.parse(this.props.answers);
        var questionPass = this.props.question;
        var questionId = this.props.questionId;
        const multiplesInDiv = (
            answers.map(answer => (
                <tr key={Math.random(0, 5000)}
                    style={{display: "table",margin: "10px",marginLeft: "25px"}}>
                    <td key={Math.random(0, 5000)}>
                        <input type='radio'
                            checked={true}
                            key={Math.random(0, 5000)}
                            id={answer.id + "|" + questionId}
                            value={answer.id}
                            name={questionPass}
                            //onClick={e => alert(e.target.id)}
                            onChange={this.handleOptionChange}  />
                    </td><td key={Math.random(0, 5000)}>
                        <label key={Math.random(0, 5000)} htmlFor={answer.id}>{answer.answer}</label>
                    </td>
                </tr>
            ))
        );

        return multiplesInDiv;
    }
}

class LongQuestion extends React.Component {
    render() {
        const question = this.props.question;

        const multiplesInDiv = (
            <tr key={Math.random(0, 5000)}>
                <td>
                    <input type='text' key={Math.random(0, 5000)} id={question.id} name={question.id} src={question.href} alt={question.name} onClick={e => alert(e.target.alt)} />
                </td><td>
                    <label key={Math.random(0, 5000)} htmlFor={question.id}>{question.id}</label>
                </td>
            </tr>
        );
        return multiplesInDiv;
    }
}


/**TODO:
 * Increased point value to short answers.
 * Text area, not input
**/


export default Quiz;