import React from 'react';
import ClassDropdown from "./classdropdown";
import GradePicker from './gradepicker';
import StudentField from './studentfield';
import Quiz from './senderQuiz';
import './global.js';

class QuizSender extends React.Component {

    constructor(props) {
        super(props);
        this.state ={
            quizId: 22,
            blurb: '',
            quizName: '',
            course: 0,
            grade: 0,
            school: 0,
            courses: [],
            students: [],
            quizes: [],
            schools: [],
            questions: [],
            selectedStudents:[],
        };
    }

    componentDidMount = () => {        
        fetch(global.server + "quiznames/read.php").then(res => res.json()).then((result) => {
            this.setState({
                quizes: result,
            })
        }).then(
            fetch(global.server + 'schools/read.php').then(res => res.json()).then((result) => {
                this.setState({
                   schools: result,    
                })
            })
        );
    }
    
    setStudents = (passedValue) => {
        fetch(global.server + 'students/read.php?course=' + passedValue)
            .then(res => res.json())
            .then(result => {
                const passedResult = [];
                result.users.map(student => {
                    passedResult.push(student.user_id);
                    return null;
                });
                this.setState({
                    selectedStudents: passedResult,
                })
            })
    }

    getCourses = (school,grade) => {
        fetch(global.server + 'classes/read.php?school=' + school + '&grade=' + grade)
            .then(res => res.json())
            .then(result => {
                this.setState({
                    courses: result.courses,
                })
            });
    }

    setGrade = e => {
        const inGrade = parseInt(e.target.value);
        this.setState({
            grade: inGrade,
        });

        this.setStudentArray(this.state.school,inGrade);
        this.getCourses(this.state.school,inGrade);
    }

    setSchool = e => {
        const inSchool = parseInt(e.target.value);
        this.setState({
            school: inSchool,
        });
        this.setStudentArray(inSchool,this.state.grade);
        this.getCourses(inSchool,this.state.grade);
    }
    setStudentArray = (schoolIn,gradeIn) => {
        fetch(global.server + 'students/read.php?school=' + schoolIn + "&grade=" + gradeIn)
            .then(res => res.json())
            .then(result => {
                this.setState({
                    students: result.users,
                })
            });
    }


    setCourse = e => {
        const inCourse = parseInt(e.target.value);
        this.setState({
            course: inCourse,
        });
        this.setStudents(inCourse);
    }


    updateQuiz = e =>{
        const newQuizId = parseInt(e.target.value);
        if(newQuizId === 0)
        {    
            this.setState({
                quizId: newQuizId,
                questions: [],
            },
            this.repopulateQuiz(newQuizId))
        }else{
            this.setState({
                quizId: newQuizId,
            },
            this.repopulateQuiz(newQuizId))
        }
    }
    

    repopulateQuiz = (quizId) => {
        fetch(global.server + "quizes/read.php?id=" + quizId).then(res => res.json()).then((result) => {
            this.setState({
                questions: result.questions,
                blurb: result.blurb,
                quizName: result.quizName,
            });
        });
    }



    checkAll = () => {
        const array = [];
        this.state.students.map(student => {
            array.push(student.user_id);
            return null;
        });
        this.setState({
            selectedStudents: array,
        })
    }
    uncheckAll = () =>{
        this.setState({
            selectedStudents: [],
        })
    }

    
    sendQuiz = () => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                quiz:this.state.quizId,
                users: this.state.selectedStudents
            }),
        };
        
        fetch(global.server + "user_required_quizes/create.php",requestOptions).then(res => res.json()).then(result => {
            if(result.result)
            {
                alert("Sent Quiz");
            }else{
                alert("Failed To Sent Quiz");
            }
        });
    }

    updateStudents = e => {
        const student = parseInt(e.target.id);
        const array = [...this.state.selectedStudents];
        if (e.target.checked) {
            array.push(student);
            this.setState({
                selectedStudents: array,
            })
        } else {
            const array = [...this.state.selectedStudents];
            let deleteIndex = -1;
            for (let index = 0; index < this.state.selectedStudents.length; index++) {
                if (this.state.selectedStudents[index] === student) {
                    deleteIndex = index;
                    break;
                }
            }
            array.splice(deleteIndex, 1);

            this.setState({
                selectedStudents: array,
            });
        }
    }

    sendToSchool = () => {
        // let val = document.getElementById("activeNow").checked;
        // let hold = "";
        if(this.state.school !== 0)
        {
            // if(val)
            // {
            //     hold = "&priority=1";
            // }
            // fetch(global.server + "sendQuiz.php?quiz=" + this.state.quizId + "&school=" + this.state.school + hold).then(alert("Sent Quiz"));
            
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    quiz:this.state.quizId,
                    users: this.state.selectedStudents,
                    priority:1
                }),
            };
            
            fetch(global.server + "user_required_quizes/create.php",requestOptions).then(res => res.json()).then(result => {
                if(result.result)
                {
                    alert("Sent Quiz");
                }else{
                    alert("Failed To Sent Quiz");
                }
            });
        }else{
            alert('Please Select A School');
        }
    }
    sendToGrade = () => {
        let val = document.getElementById("activeNow").checked;
        let hold = "";
        if(this.state.school !== 0)
        {
            if(this.state.grade !== 0)
            {
                if(val)
                {
                    hold = "&priority=1";
                }        
                fetch(global.server + "sendQuiz.php?quiz=" + this.state.quizId + "&school=" + this.state.school + "&grade=" + this.state.grade + hold).then(alert("Sent Quiz"));    
            }else{
                alert("Please Select A Grade");
            }
        }else{
            alert('Please Select A School');
        }
    }
    sendToCourse = () => {
        let val = document.getElementById("activeNow").checked;
        let hold = "";
 
        if(this.state.school !== 0)
        {
            if(this.state.grade !== 0)
            {
                if(this.state.course !== 0)
                {
                    if(val)
                    {
                        hold = "&priority=1";
                    }
                    fetch(global.server + "sendQuiz.php?quiz=" + this.state.quizId + "&course=" + this.state.course + hold).then(alert("Sent Quiz"));    
                }else{
                    alert("Please Select A Course");
                }
            }else{
                alert("Please Select A Grade");
            }
        }else{
            alert('Please Select A School');
        }
    }

    stopQuizes = () => {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                priority:0,
            }),
        };
        
        fetch(global.server + "user_required_quizes/update.php",requestOptions).then(res => res.json()).then(result => {
            alert("Stoped Quizes")
        });
    }


    sendQuizNow = () => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                quiz:this.state.quizId,
                users: this.state.selectedStudents,
                priority:1
            }),
        };
        
        fetch(global.server + "user_required_quizes/create.php",requestOptions).then(res => res.json()).then(result => {
            if(result.result)
            {
                alert("Sent Quiz");
            }else{
                alert("Failed To Sent Quiz");
            }
        });
    }



    render() {

        const multiplesInDiv = (
            <div>
                <h1>Quiz Sender</h1>
                <div className='smallcard'>
                <QuizSelect quizId={this.state.quizId} updateQuiz={this.updateQuiz} quizes={this.state.quizes}></QuizSelect>
                <Quiz blurb={this.state.blurb} questions={this.state.questions}></Quiz>
                </div>
                <div className='smallcard'>
                <table><thead></thead><tbody>
                    {/* <tr>
                        <td><button onClick={this.sendToSchool}>Send To School</button></td>
                        <td><button onClick={this.sendToGrade}>Send To Grade</button></td>
                        <td><button onClick={this.sendToCourse}>Send To Course</button></td>
                        <td></td>
                    </tr> */}
                    <tr><td>
                    <SchoolSelect setSchool={this.setSchool} schools={this.state.schools} school={this.state.school}>{this.props.children}</SchoolSelect>
                    </td><td>
                    <GradePicker setGrade={this.setGrade} grade={this.state.grade}>{this.props.children}</GradePicker>
                    </td><td>
                    <ClassDropdown course={this.state.course} setCourse={this.setCourse} courses={this.state.courses}>{this.props.children}</ClassDropdown>
                    </td></tr></tbody><tfoot></tfoot></table>
                    <StudentField checkAll={this.checkAll} uncheckAll={this.uncheckAll} updateStudents={this.updateStudents} selectedStudents={this.state.selectedStudents} students={this.state.students}>{this.props.children}</StudentField>
                </div>
                <table><thead></thead><tbody>
                    <tr>
                        <td>
                        <button onClick={this.stopQuizes}>Stop Quizes</button>
                        </td><td>
                        <button onClick={this.sendQuizNow}>Send Quiz Now</button>
                        </td><td>
                        <button onClick={this.sendQuiz}>Add Quiz To Queue</button>
                        </td>
                </tr></tbody><tfoot></tfoot></table>
            </div>
        );

        return multiplesInDiv;
    }
}

class QuizSelect extends React.Component {

    render() {
        const multiplesInDiv = (
            <select id="QuizSelect" onChange={this.props.updateQuiz} value={this.props.quizId}>
                <option id={0} value={0}>Please Select A Quiz</option>
                {
                    this.props.quizes.map(quiz =>(
                        <option value={quiz.id} key={Math.random(50000)}>{quiz.quiz_name}</option>
                    ))
                }
            </select>
            );
            return multiplesInDiv;
        }
    }


/**TODO:
 * Scheduled send functionality.
 * Check for Date Required quiz before going to the next un-answered quiz
 */


class SchoolSelect extends React.Component {
    render() {
        const multiplesInDiv = (
            <select id='schoolIdSelect' onChange={this.props.setSchool} value={this.props.school}>
                <option id={0} value={0}>Please Select A School</option>
                {this.props.schools.map(school => (
                    <option id={school.id} value={school.id} key={Math.random(50000)}>{school.school}</option>
                ))}
            </select>
        );
        return multiplesInDiv;
    }
}

export default QuizSender;