import React from 'react';
import Conversations from './conversations';
import Conversation from './conversation';
import './messenger.css';
import './global.js';


class Messenger extends React.Component {
    constructor(props) {
        super(props);
        this.setLoading = this.setLoading.bind(this);
        this.state = {
            contact: {},
            conversations: [],
            conversation: [],
            userId: 0,
            inConversation:false,
            isLoading:false,
            school:0,
        }
        this.state.userId = this.props.userId;
    }

    componentDidMount = () => {
        this.setState({
            school: this.props.school
        },
        this.setContacts());
    }

    setContacts = () => {
        fetch(global.server + "messages/read.php?Auth=" + this.props.auth + "&UserId=" + this.props.userId + "&school=" + this.state.school).then(res => res.json()).then(result => {
            this.setState({
                conversations: result,
            });
        });
    }

    setContact = contactId => {
        this.state.conversations.forEach(element => {
            if (parseInt(element.toUserId) === parseInt(contactId)) {
                fetch(global.server + "messages/read.php?MyId=" + this.props.userId + "&TheirId=" + contactId).then(res => res.json()).then(result => {
                    this.setState({
                        contact: element,
                        conversation: result,
                        inConversation: true,
                    })
                })
                .then(
                    fetch(global.server + "messages/update.php?myId=" + this.props.userId + "&theirId=" + contactId).then(result => {
                        console.log(result);
                    })
                );
            }
        });
    }


    updateConversation = (passToId) => {
        fetch(
          global.server + "message/read.php?userId=" +
            this.state.userId +
            "&convoWithId=" +
            passToId
        )
          .then((res) => res.json())
          .then((response) => {
            this.setState({
              toId: passToId,
              messages: response,
            });
          });
      };
    
    // updateConversation = () => {
    //     this.setState((prevState,props) => {
    //         fetch(global.server + "getMessages.php?MyId=" + props.userId + "&TheirId=" + prevState.contact.toUserId).then(res => res.json()).then(result => {
    //             return{
    //                 conversation: result,
    //             }
    //         });
    //     });
    // }

    setLoading = (ball) => {
        this.setState({
            isLoading:ball,
        })
    }


    render() {
        const multiplesInDiv = (
            <div className="messengerBlock">
                <div className={this.state.inConversation?"messenger-hidden":"messenger-visible"} id="conversations">
                    <Conversations
                        conversations={this.state.conversations}
                        setContact={this.setContact}
                        userId={this.props.userId}
                        setContacts={this.setContacts}
                        setLoading={this.setLoading}
                    />
                </div>
                <div className={this.state.inConversation?"messenger-visible":"messenger-hidden"} id="conversation">
                    <Conversation
                        conversation={this.state.conversation}
                        contact={this.state.contact}
                        userId={this.props.userId}
                        back={() => {this.setState({inConversation:false})}}
                        updateConversation={this.updateConversation}
                        setLoading={this.setLoading}
                    />
                </div>
            </div>
        );

        return multiplesInDiv;
    }
}

export default Messenger;