import React from 'react';

import Messenger from './messenger';
import PointGiver from './pointgiver';



class Teacher extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            theirId:0,
            auth:0,
            userId:0,
            courses:[],
            school:0,
        }
        let user = this.props.user;
        this.state.theirId = user.theirId;
        this.state.auth = user.auth;
        this.state.userId = user.userId;
        this.state.courses = user.courses;
        this.state.school = user.school;
    }
    render() {
        const multiplesInDiv = (
            <div>
                <div className='smallcard'>
                    <PointGiver school={this.state.school} userId={this.state.userId} auth={this.state.auth} grade={this.state.grade}></PointGiver>
                </div>
                <div className='smallcard'>
                    <Messenger school={this.state.school} userId={this.state.userId} auth={this.state.auth}></Messenger>
                </div>
            </div>
        );

        return multiplesInDiv;
    }
}

/**TODO:
 * Teacher specific point adder
 * Button on point adder
 */

export default Teacher;