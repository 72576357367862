import React from 'react';
import './messenger.css';
import ReactLoading from 'react-loading';
import './global.js';


class Messages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            incrementId: '',
            conversation: [],
            delayTime: 5,
            userId: 0,
            contact: {},
            isLoading: false,
        }
    }



    componentDidMount = () => {
        this.loadMessages();
        //this.updateConversation();
        const incrementId = setTimeout(() => this.updateConversation(), 4000);
        this.setState({
            incrementId: incrementId,
        });
    }

    componentWillUnmount = () => {
        window.clearTimeout(this.state.incrementId);
    }

    updateConversation = () => {
        this.loadMessages();//.loadConversation();
        const objDiv = document.getElementById("messageField");
        if (objDiv !== null) {
            const incrementId = setTimeout(() => this.updateConversation(), 4000);
            this.setState({
                incrementId: incrementId,
            });
            objDiv.scrollTop = objDiv.scrollHeight;
        }
    }

    back = () => {
        this.props.back();
        this.setState({
            conversation:[],
            redirect: true,
        })
    }

    sendMessage = () => {
        let message = document.getElementById("messegeValue").value;
        document.getElementById("messegeValue").value = '';
        let hold = [...this.state.conversation];
        hold.push({
            to: this.props.contact.toUserId,
            message: message,
        },() => this.loadMessages());

        this.setState({
            conversation: hold,
        })
        fetch(global.server + "sendMessage.php?Message=" + message + "&MyId=" + this.props.userId + "&TheirId=" + this.props.contact.toUserId)
            .then(res => res.json())
            .then(result => {
                console.log(result);
            });
    }

    loadMessages = () => {
        if(this.props.contact.toUserId !== undefined)
        {
            fetch(
                global.server + "getMessages.php?MyId=" + this.props.userId + "&TheirId=" + this.props.contact.toUserId
            )
                .then((res) => res.json())
                .then((response) => {
                    this.setState({
                        toId: this.state.contact.toUserId,
                        conversation: response,
                    });
                });
            }
    }


    pressEnter = e => {
        if (e.key === 'Enter') {
            document.getElementById("sendButton").click();
        }
    }


    render() {
        // if (this.state.redirect) {
        //     document.getElementById("conversation").className = "messenger-hidden";
        //     document.getElementById("conversations").className = "messenger-visible";
        // }
        let loading;
        if(this.state.isLoading)
        {
            loading = (
            <div className="loading-screen">
              <img src="logo192.png" alt="involvED" className="signin-logo" />  
              <ReactLoading className="loading-bars" type={"bars"} color="grey" />
            </div>);
        }
        const multiplesInDiv = (
            <div className="conversationBlock">
                <div className="messenger-nav">
                    <button style={{ float: "left" }} onClick={this.back}>Back</button>
                    <p>{this.props.contact.toUser}</p>
                </div>
                {loading}
                <div className="messageField" id="messageField">
                    {
                        this.state.conversation.map(message => {
                            return <Message
                                key={Math.random(5000)}
                                contact={this.props.contact}
                                message={message}
                                userId={this.props.userId}></Message>
                        })
                    }
                    {/* <p>{this.state.incrementId}</p> */}
                </div>
                <div className="sendBar">
                    <input id="messegeValue" placeholder={"Message " + this.props.contact.toUser} onKeyDown={this.pressEnter} />
                    <button id="sendButton" onClick={this.sendMessage}>Send</button>
                </div>
            </div>
        );

        return multiplesInDiv;
    }
}

class Message extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        let toMe = (parseInt(this.props.message.to) === this.props.userId);
        const multiplesInDiv = (
            <div key={Math.random(5000)} className={toMe ? "msg-left" : "msg-right"}>
                <p key={Math.random(5000)}>{this.props.message.message}</p>
                <small key={Math.random(5000)}>{toMe ? this.props.contact.toUser : "You"}</small>
            </div>
        );

        return multiplesInDiv;
    }

}


/**
 * ToDo:
 *  check for updated messages the pull only those, 
 *  then append those to state 
 * 
 *  on school change update messages... ADMIN
 *  faster reload of messages when you switch users
 *  loading screen implementations
 */

export default Messages;