import React from 'react';
import './global.js';



class PointPicker extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            points:[],
        }
        
        fetch(global.server + 'points/read.php?school=' + this.props.school + "&ClassId=" + this.props.classId).then(res=>res.json()).then((result) =>{
            this.state.points = result;
        });
    }


    render() {
        const multiplesInDiv = (
            <div>
                <select id='pointIdSelect' onChange={this.props.setPoints} value={this.props.points}>
                    <option id={0} value={0} disabled defaultValue>Please Select A Point Option</option>
                    {this.state.points.map(point => (
                        <option id={point.id} value={point.id} key={Math.random(0,50000)}>{point.activity}</option>
                    ))}
                </select>
            </div>
        );

        return multiplesInDiv;
    }
}

export default PointPicker;