import React from 'react';


class ClassDropdown extends React.Component {
    
    render() {
        const multiplesInDiv = (
            <div>
                <select id="classIdSelect" onChange={this.props.setCourse} value={this.props.course} >
                    <option id={0} value={0}>Please Select A Class</option>
                    {this.props.courses.map(course => (
                        <option id={course.id} value={course.id} key={Math.random(0,50000)}>{course.class}</option>
                    ))}
                </select>
            </div>
        );

        return multiplesInDiv;
    }
}

export default ClassDropdown;