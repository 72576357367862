import React from 'react';
import QrReader from 'react-qr-reader'


class QRReader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            result: 'No result'
        }
    }


    handleScan = data => {
        if (data) {
            this.setState({
                result: data
            })
        }
    }
    handleError = err => {
        console.error(err)
    }
    render() {
        return (
            <div>
                <h1>Scan Your User Card Here</h1>
                <div className='qrreader'>
                    <QrReader
                        delay={300}
                        onError={this.handleError}
                        onScan={this.handleScan}
                        style={{ width: '100%' }}
                    />
                    <p>{this.state.result}</p>
                </div>
            </div>
        )
    }/** */
}

export default QRReader;