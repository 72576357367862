import React from 'react';
import QRCode from "react-qr-code";

 
class QRMaker extends React.Component {
    render() {
        const multiplesInDiv = (
            <div className="center">
                <h1 style={{margin:"0"}}>User Card</h1>
                <QRCode value={this.props.userId}></QRCode>
            </div>
        );

        return multiplesInDiv;
    }
}

export default QRMaker;